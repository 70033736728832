// @flow

import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { createFragmentContainer, graphql } from 'react-relay'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import { getUser, getUkLogo } from '../../utils/commonSelectors'
import Tabs from '../../components/MarketplaceTabs'
import CreateProviderPageMutation from '../CreateProviderPage/CreateProviderPage.mutation'
import { MARKETPLACE_ROUTES } from '../../constants'
import Button from '../../components/Button'
import { getAvatar } from '../../utils/utils'
import EditHeaderForm from './EditHeaderForm'
import EditDescriptionForm from './EditDescriptionForm'
import EditContactForm from './EditContactForm'
import { USER_GROUPS } from '../../constants'
import { newLineToBr, linkify } from '../../utils/text'

import 'antd/es/button/style/index.css'
import styles from './ProviderProfile.module.scss'

type Props = {
  provider: Object,
}

const EDIT_MODE_CONTACTS = 'contacts'
const EDIT_MODE_DESCRIPTION = 'description'
const EDIT_MODE_HEADER = 'header'

// TODO разнести на разные режимы редактирования: хэдер, описание, таб контактов
// TODO таб контактов необходимо создать

const ProviderProfile = (props: Props) => {
  const { t } = useTranslation('Providers')
  const user = useSelector(state => getUser(state))
  const logo = useSelector(state => getUkLogo(state))
  const ukPhone = useSelector(state => state.init.uk.phone)
  const [editMode, setEditMode] = useState(null)
  const {
    provider: {
      description,
      image,
      name,
      address,
      editable,
      id,
      contacts,
      workTime,
    },
  } = props

  const redirectToCreateOrder = () => {
    props.history.push({
      pathname: MARKETPLACE_ROUTES['marketplaceCreateOrder'],
      search: `?provider=${id}&name=${name}&avatar=${image.origin}`,
    })
  }

  const cancelEditMode = () => {
    setEditMode(null)
  }

  const handleSubmit = (
    key,
    values,
    successCallback = null,
    errorCallback = null
  ) => {
    const inputData = { sendInvite: false }
    switch (key) {
      case EDIT_MODE_HEADER:
        inputData.contacts = contacts.map(contact => ({
          type: contact.type,
          value: contact.value,
        }))
        inputData.description = description
        inputData.id = id
        inputData.workTime = workTime
        break

      case EDIT_MODE_CONTACTS:
        inputData.description = description
        inputData.id = id
        inputData.name = name
        inputData.address = address
        break
      case EDIT_MODE_DESCRIPTION:
        inputData.name = name
        inputData.contacts = contacts.map(contact => ({
          type: contact.type,
          value: contact.value,
        }))
        inputData.id = id
        inputData.address = address
        inputData.workTime = workTime
        break
      default:
        break
    }

    const thumbnail = (logo && (logo.thumbnail || logo.origin || '')) || ''

    CreateProviderPageMutation.commit(
      {
        pmcPhone: ukPhone || '',
        pmcLogo: thumbnail,
        ...inputData,
        ...values,
      },
      (info, errors) => {
        if (errors && errors.length > 0) {
          // TODO show errors
          const errorData = JSON.stringify(errors)
          // captureException(errorData)
          console.error(errorData)
          errorCallback && errorCallback()
        } else {
          // TODO if key == contacts -> refetch() https://relay.dev/docs/en/classic/refetch-container#refetch
          const { mutateProviderEx } = info

          if (mutateProviderEx && 'provider' in mutateProviderEx) {
            // todo перезапрашивать данные о провайдере, чтобы обновить виджет
            cancelEditMode()
            const { provider } = mutateProviderEx

            if (provider) {
              props.refetch()
              successCallback && successCallback()
            } else {
              errorCallback && errorCallback()
              // TODO show error
            }
          }
        }
      },
      error => {
        console.error(error)
        errorCallback && errorCallback()
        // error callback
      }
    )
  }

  const renderEditHeaderForm = function () {
    return (
      <EditHeaderForm
        // TODO imageid + state for imageid to reset
        providerData={{
          name,
          address,
          image,
        }}
        onSubmit={(values, successCallback, errorCallback) => {
          handleSubmit(EDIT_MODE_HEADER, values, successCallback, errorCallback)
        }}
        onCancel={cancelEditMode}
      />
    )
  }

  const renderEditDescriptionForm = function () {
    return (
      <EditDescriptionForm
        description={description}
        onSubmit={(values, successCallback, errorCallback) => {
          handleSubmit(
            EDIT_MODE_DESCRIPTION,
            values,
            successCallback,
            errorCallback
          )
        }}
        onCancel={cancelEditMode}
      />
    )
  }

  const renderEditContactForm = function () {
    return (
      <EditContactForm
        editable={editable}
        contacts={contacts}
        workTime={workTime}
        onSubmit={(values, successCallback, errorCallback) => {
          handleSubmit(
            EDIT_MODE_CONTACTS,
            values,
            successCallback,
            errorCallback
          )
        }}
        // onEdit={() => setEditMode(EDIT_MODE_CONTACTS)}
        // onCancel={cancelEditMode}
      />
    )
  }

  const renderCurrentTab = function (key) {
    switch (key) {
      case EDIT_MODE_DESCRIPTION:
        return (
          <div className={styles.block}>
            <div className={styles.description}>
              <div className={styles['contacts-header']}>
                <span>{t('description')}</span>
                {editable && editMode !== EDIT_MODE_DESCRIPTION && (
                  <Button.Edit
                    onClick={() => {
                      setEditMode(EDIT_MODE_DESCRIPTION)
                    }}
                  />
                )}
              </div>
              <div className={styles.contacts}>
                {editMode === EDIT_MODE_DESCRIPTION ? (
                  renderEditDescriptionForm()
                ) : (
                  <span
                    className={styles.linked}
                    dangerouslySetInnerHTML={{
                      __html: linkify(newLineToBr(description)),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )
      case EDIT_MODE_CONTACTS:
        return <div className={styles.block}>{renderEditContactForm()}</div>
      default:
        return null
    }
  }

  const tabs = [
    {
      id: 'description',
      text: t('description'),
      content: renderCurrentTab(EDIT_MODE_DESCRIPTION),
    },
    {
      id: 'contacts',
      text: t('contacts'),
      content: renderCurrentTab(EDIT_MODE_CONTACTS),
    },
  ]

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.header}>
          {editMode === EDIT_MODE_HEADER ? (
            renderEditHeaderForm()
          ) : (
            <>
              <div className={styles['header-upper']}>
                <img
                  className={styles['header-avatar']}
                  src={getAvatar(image)}
                />
                <div className={styles['header-info']}>
                  <div style={{ display: 'flex' }}>
                    <div className={styles['header-name']}>{name}</div>
                    {editable && (
                      <Button.Edit
                        onClick={() => {
                          setEditMode(EDIT_MODE_HEADER)
                        }}
                      />
                    )}
                  </div>
                  <div className={styles['header-address']}>{address}</div>
                </div>
              </div>
              {user.group !== USER_GROUPS.dweller && (
                <div className={styles['header-bottom']}>
                  <Button.Regular type='button' onClick={redirectToCreateOrder}>
                    {t('createOrder')}
                  </Button.Regular>
                </div>
              )}
            </>
          )}
        </div>

        <Tabs items={tabs} />
        <div className={classnames(styles.block, styles['block_margin'])}>
          <div className={styles['contacts-header']}>{t('offers')}</div>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default createFragmentContainer(withRouter(ProviderProfile), {
  provider: graphql`
    fragment ProviderProfile_provider on ProviderNode {
      id
      name
      description
      editable
      workTime
      address
      user {
        id
      }
      contacts {
        type
        value
        verboseName
      }
      image {
        origin
        alt
        thumbnails {
          size
          url
        }
      }
    }
  `,
})
