// @flow

import React from 'react'
import type { Node } from 'react'

import { newLineToBr, linkify } from '../../../../utils/text'

import styles from './Text.module.scss'

type Props = { text: string }

const Text = (props: Props): Node => {
  const { text } = props

  return (
    <div
      className={styles.text}
      dangerouslySetInnerHTML={{ __html: linkify(newLineToBr(text)) }}
    />
  )
}

export default Text
