// @flow

import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import type { Node } from 'react'

import {
  getSnippetVariables,
  getEmailVariables,
} from '../../core/api/api.requestConstructor'

import Button from '../Button'
import { removeFile } from '../../utils/file'
import { getUserId, getUrlForAvatar, isStaffUser } from '../../utils/utils'
import { getUser } from '../../utils/commonSelectors'
import { renderTemplate } from '../../core/api/api.template'
import { renderEmailTemplate } from '../../core/api/api.emailTemplate'
import RequestFileList from '../Emails/RequestFileList'
import NewTemplateDropdown from '../NewTemplateDropdown'
import WysiwygEditor from '../WysiwygEditor'
import NewVariableDropdown from '../NewVariableDropdown'
import { newLineToBr } from '../../utils/text'
import SelectTemplateModal from './SelectTemplateModal'
import SelectAnalyzeTemplateModal from './SelectAnalyzeTemplateModal'
import { TEMPLATE_TYPE } from '../../constants'
import RequestFilesModal from './RequestFilesModal'
import NewFilesUpload from '../NewFilesUpload'
import styles from './SendMessageForm.module.scss'
import ExtraTools from '../Mail/NewMailReplyForm/ExtraTools'
import RouterPrompt from '../Mail/RouterPrompt'
import TemplateVariables from '../Templates/TemplatePopup/TemplateVariables'
import { isEqual, isNil } from 'lodash-es'

const MAX_FILES_SIZE = 9 * 1024 * 1024
const { EMAIL, DOCS } = TEMPLATE_TYPE

type Props = {
  autoFocus?: boolean,
  buildingId?: number,
  canSubmit?: boolean,
  count: number,
  docTemplateId?: number,
  editorDisabled?: boolean,
  files: Array<Object>,
  filesAsLinks: boolean,
  filesHeaderTitle?: string,
  flatId?: number,
  hasVariables: boolean,
  isAutomation?: boolean,
  isEmail?: boolean,
  isMass?: boolean,
  noFileLimit?: boolean,
  onAnalyze?: number => void,
  onFilesUploading?: Function,
  onlyManualFileRemove?: boolean,
  onPreview?: number => void,
  onRemoveDocs?: () => void,
  onReplaceTemplate?: () => void,
  onSubmit?: () => void,
  onToggleMass?: boolean => void,
  onUpdateFiles: (Array<Object>) => void,
  onUpdateFilesAsLinks: boolean => void,
  onUpdateTitle?: string => void,
  outbound?: string,
  postUuid?: ?string,
  proceedExt?: boolean,
  quillProps: Object,
  recipientId?: number,
  removeExternalFile: number => void,
  renderAdditional?: () => Node,
  renderFooter?: () => ?Node,
  renderHeader?: () => ?Node,
  replyText?: string,
  requestId?: ?number,
  resetValues?: () => void,
  saveOriginFileIds?: Array<number>,
  t: string => string,
  templateParams?: Object,
  user: Object,
  uuid?: string,
  working?: boolean,
}

type State = {
  filesUploading: boolean,
  isFilesModalOpen: boolean,
  selectAnalyzeTemplateModal: boolean,
  selectTemplateModal: boolean,
  showTemplates: boolean,
  template: ?Object,
  uploadingFiles: Array<Object>,
}

class SendMessageForm extends Component<Props, State> {
  static defaultProps = {
    files: [],
  }

  state = {
    filesUploading: false,
    uploadingFiles: [],
    showTemplates: false,
    isFilesModalOpen: false,
    template: null,
    selectTemplateModal: false,
    selectAnalyzeTemplateModal: false,
    onDrag: false,
    isFocusedEditor: false,
    submited: false,
    prevValues: {},
    prevFiles: {},
    filePreview: null,
    tags: [],
  }

  templateRef = createRef()
  buttonRef = createRef()

  changedValues = () => {
    const { prevValues, prevFiles } = this.state
    const { values, files } = this.props

    let isModifiedValues =
      Object.keys(prevValues).find(key => {
        if (typeof prevValues[key] === 'object') {
          return !isEqual(prevValues[key], values[key])
        } else {
          return prevValues[key] !== values[key]
        }
      })?.length > 0

    let issModifiedFile = !isEqual(prevFiles, files)

    return isModifiedValues || issModifiedFile
  }

  onUnload = e => {
    if (this.changedValues()) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  componentDidMount() {
    const {
      isChatroom,
      isConstructor,
      values,
      files,
      isToHided,
      isAutomation,
    } = this.props

    let tags = ['email', 'requestthread_email']

    if (isToHided) {
      tags = ['request_comment']

      if (!isAutomation) {
        tags.push('chat')
      } else {
        tags.push('automation')
      }
    } else if (!isAutomation) {
      tags.push('mass_emailing')
    } else {
      tags.push('automation')
    }

    this.setState({ tags })
    this.setState({ prevValues: { ...values } })
    this.setState({ prevFiles: [...files] })
    !isChatroom &&
      !isConstructor &&
      window.addEventListener('beforeunload', this.onUnload)
  }

  componentDidUpdate(prevProp) {
    const { values, isChangedValues, isChatroom, isConstructor } = this.props

    if (
      !isChatroom &&
      !isConstructor &&
      this.props.setChangedValues &&
      this.changedValues() !== isChangedValues
    ) {
      this.props.setChangedValues(this.changedValues())
    }

    if (
      !isChatroom &&
      !isConstructor &&
      !this.state.isFocusedEditor &&
      !this.state.template &&
      prevProp?.values?.message !== values?.message
    ) {
      this.setState(prev => ({
        prevValues: { ...prev.prevValues, message: values.message },
      }))
    }
  }

  componentWillUnmount() {
    this.removeFiles()
    this.props.setChangedValues && this.props.setChangedValues(false)
    window.removeEventListener('beforeunload', this.onUnload)
  }

  startFilesUpload = () => {
    this.setState({ filesUploading: true })
    this.props.onFilesUploading && this.props.onFilesUploading()
  }

  handleInit = (e, editor) => {
    this.templateRef.current = editor
  }

  showUploading = (filesArr: Array<Object>) => {
    this.setState({ uploadingFiles: filesArr })
  }

  finishFilesUpload = (uploaded: Array<Object>, replace: boolean) => {
    this.setState({ onDrag: false })
    const { files, docTemplateId } = this.props
    const nextFiles = replace
      ? files.filter(file => file.id === docTemplateId).concat(uploaded)
      : files.filter(file => !file.isExternalFile).concat(uploaded)

    this.props.onUpdateFiles(nextFiles)

    this.setState({ filesUploading: false, uploadingFiles: [] })
  }

  removeFile = ({ id, doc }: Object) => {
    const { files } = this.props

    this.props.onUpdateFiles(
      files.filter(file => !file.isExternalFile).filter(f => f.id !== id)
    )

    if (!doc) {
      this.removeFileOrigin(id)
    }
  }

  removeFiles = () => {
    const { files, working, onlyManualFileRemove } = this.props

    if (!working && !onlyManualFileRemove) {
      files
        .filter(file => !file.isExternalFile)
        .forEach(f => this.removeFileOrigin(f.id))
    }
  }

  removeExternalFile = ({ id }: Object) => {
    this.props.removeExternalFile(id)
  }

  removeFileOrigin = (id: number) => {
    const { saveOriginFileIds, outbound } = this.props

    if (!saveOriginFileIds || !saveOriginFileIds.includes(id)) {
      removeFile(id, outbound) // TODO mass action ??
    }
  }

  submit = () => {
    window.removeEventListener('beforeunload', this.onUnload)

    if (this.props.onSubmit) {
      this.props.onSubmit()
      this.setState({ template: null, submited: true })
    }
  }

  cancel = () => {
    if (this.props.resetValues) {
      this.props.resetValues()
      this.setState({ template: null })
    } else {
      const editor = this.templateRef.current

      if (editor) {
        editor.resetContent()
        editor.focus()
        this.removeFiles()
        this.props.onUpdateFiles([])
      }
    }
  }

  handlePreview = () => {
    this.props.onPreview(this.state.template?.id)
  }

  handleAnalyze = () => {
    this.props.onAnalyze({
      author_id: getUserId(this.props.user),
      id: this.state.template?.id,
      templateType: EMAIL,
    })
  }

  confirmHandleAnalyze = () =>
    this.setState({ selectAnalyzeTemplateModal: true })

  analyzeEmail = () => {
    this.handleAnalyzeForce(EMAIL)
    this.hideSelectAnalyzeTemplateModal()
  }

  analyzeDoc = () => {
    this.handleAnalyzeForce(DOCS)
    this.hideSelectAnalyzeTemplateModal()
  }

  handleAnalyzeForce = templateType => {
    this.props.onAnalyze({
      author_id: getUserId(this.props.user),
      id:
        templateType === EMAIL
          ? this.state.template.id
          : this.props.docTemplateId,
      templateType,
      force: true,
    })
  }

  renderHeader = () => !!this.props.renderHeader && this.props.renderHeader()

  renderAdditional = () =>
    !!this.props.renderAdditional && this.props.renderAdditional()

  handleRemove = file => {
    const onRemove = file.isExternalFile
      ? this.removeExternalFile
      : this.removeFile

    return onRemove(file)
  }

  handleFilesAsLinks = e => {
    this.props.onUpdateFilesAsLinks(e.currentTarget.checked)
  }

  handleSelect = option => {
    const { uuid, isAutomation } = this.props
    const message = this.props.values?.message
    const prevMessage = this.state.prevValues?.message
    const messageChanged =
      message && message.length > 0 && message !== prevMessage

    const title = this.props.values?.title
    const titleChanged = option.subject && title && option.subject !== title

    const contentChanged = messageChanged || titleChanged

    if (
      ((uuid === 'create' || isAutomation) && contentChanged) ||
      this.state.template
    ) {
      this.setState({ selectTemplateModal: true, template: option })
    } else {
      this.setState({ template: option }, () => this.confirmHandleSelect())
    }
  }

  confirmHandleSelect = (replace = false) => {
    this.setState({ showTemplates: false })

    const {
      requestId,
      buildingId,
      flatId,
      recipientId,
      isEmail,
      isMass,
      isRequestThread,
      isAutomation,
    } = this.props

    const params = {
      untagged_only: isMass && !isRequestThread,
      replace_undefined: !isMass,
    }

    if (requestId) {
      if (isEmail) {
        params.request_id = requestId
      } else {
        params.request_id_outside_email = requestId
      }
    }

    if (buildingId) {
      params.building_id = buildingId
    }

    if (flatId) {
      params.flat_id = flatId
    }

    if (recipientId) {
      params.recipient_id = recipientId
    }

    if (isAutomation) {
      this.setTemplateText(this.state.template, replace)
    } else {
      const api = isEmail ? renderEmailTemplate : renderTemplate
      api(this.state.template.id, params).then(data =>
        this.setTemplateText(data, replace)
      )
    }
  }

  confirmReplaceText = () => {
    this.confirmHandleSelect(true)
    this.hideSelectTemplateModal()
  }

  confirmInsertText = () => {
    this.confirmHandleSelect()
    this.hideSelectTemplateModal()
  }

  setTemplateText = (data, replace) => {
    const { isEmail, onUpdateTitle, isAutomation, isMass, count, uuid } =
      this.props

    const editor = this.templateRef.current

    let text =
      this.state.template.text_type === 'plain'
        ? newLineToBr(data.text)
        : data.text

    if (isEmail && onUpdateTitle) {
      let subject = data.subject
      const title = this.props.values?.title

      if ((isAutomation || uuid === 'create') && subject) {
        onUpdateTitle(replace || !title ? subject : `${title} ${subject}`)
      }
    }

    if (editor) {
      if (replace) {
        editor.setContent(text)
        this.props.onReplaceTemplate?.()
      } else {
        editor.insertContent(text)
      }

      editor.focus()
    }

    this.finishFilesUpload(data.files, replace)

    if (isMass && count > 0) {
      this.handleAnalyze()
    }
  }

  hideSelectTemplateModal = () => {
    this.setState({ selectTemplateModal: false })
  }

  hideSelectAnalyzeTemplateModal = () => {
    this.setState({ selectAnalyzeTemplateModal: false })
  }

  handlePasteVariable = option => {
    const editor = this.templateRef.current

    if (editor) {
      if (option.label) {
        editor.insertContent(`{{${option.label}}}`)
      } else {
        editor.insertContent(option)
      }

      editor.focus()
    }
  }

  handleOpenFiles = () => {
    this.setState({ isFilesModalOpen: true })
  }

  handleCloseModal = () => {
    this.setState({ isFilesModalOpen: false })
  }

  onFocusedEditor = () => {
    this.setState({ isFocusedEditor: true })
  }

  handleOpenPreview = fileId => {
    const { files, setActiveIndex } = this.props
    let activeIndex = files.findIndex(f => f.id === fileId)
    setActiveIndex(activeIndex)
  }

  closeLightbox = () => this.setState({ activeIndex: null })
  render() {
    const {
      t,
      quillProps,
      files,
      filesAsLinks,
      user,
      canSubmit,
      working,
      requestId,
      postUuid,
      feedback,
      isEmail,
      isDisabledPromt,
      filesHeaderTitle,
      isRequestThread,
      isAutomation,
      showAddButton,
      isChatroom,
      isConstructor,
      account,
      editorDisabled,
      hasVariables,
      count,
      isMass,
      fileListClass,
      docTemplateId,
      directoryId,
      onRemoveDocs,
      onToggleMass,
      onAnalyzeDocs,
      filesList,
      autoFocus = false,
      templateParams,
      isToHided,
      noFileLimit,
      outbound,
    } = this.props

    const { filesUploading, uploadingFiles } = this.state

    const filesSize = files.reduce((acc, item) => {
      return acc + item.size
    }, 0)

    const filesChecking = files.some(f => f.checking)

    const bodySize = quillProps.value.trim().length

    const isLimit =
      !noFileLimit &&
      !isChatroom &&
      (filesSize + bodySize > MAX_FILES_SIZE || filesChecking)
    const isBodyLimit = bodySize > MAX_FILES_SIZE

    if (!isLimit && this.props.onUpdateFilesAsLinks) {
      this.props.onUpdateFilesAsLinks(false)
    }

    const disabledSubmit =
      !canSubmit ||
      filesUploading ||
      uploadingFiles.length > 0 ||
      (!files.length && !bodySize) ||
      (isLimit && !filesAsLinks) ||
      isBodyLimit ||
      (isEmail && !account)

    const formClass = classnames({
      'working-overlay': working,
    })

    const filesClass = classnames(styles.list, fileListClass)
    const messageToolsClass = classnames(styles.tools, 'messages__tools')

    const dropClass = classnames('drop-zone')

    const onDragStart = drag => {
      this.setState({ onDrag: isNil(drag) ? true : drag })
    }

    const onShowExtraTools = !isChatroom && !isConstructor

    return (
      <>
        <RouterPrompt
          when={
            isDisabledPromt ||
            isConstructor ||
            isChatroom ||
            this.state.submited
              ? false
              : this.changedValues()
          }
          navigate={path => {
            this.props.history.push(path)
          }}
          shouldBlockNavigation={location => {
            if (
              location.pathname.includes('emails') &&
              location.pathname.includes('request')
            ) {
              return false
            }

            return true
          }}
          isModal={this.props.isModal}
          openModal={this.props.showCanLeave}
          closeMailModal={() => {
            this.props.onClose()
          }}
          setShowCanLeave={this.props.setShowCanLeave}
        />
        <div className={formClass}>
          <div className={messageToolsClass}>
            <i className='messages__tools-image'>
              <img src={getUrlForAvatar(user)} alt='message' />
            </i>
            <div className='messages__tools-input has-scroll'>
              <NewFilesUpload
                multiple
                style={{ padding: '15px' }}
                className={dropClass}
                dropzoneProps={{
                  noClick: true,
                }}
                noDrag={isChatroom}
                outbound={outbound}
                onFinishUpload={this.finishFilesUpload}
                onDragStart={onDragStart}
                onShowUpload={isEmail && this.showUploading}
              >
                <div className={this.state.onDrag ? styles.drop : {}}>
                  {this.renderHeader()}
                  {!outbound && isStaffUser(user) && (
                    <div className='reply-headers__input'>
                      <NewTemplateDropdown
                        showHeader
                        isRequestThread={isRequestThread}
                        isAutomation={isAutomation}
                        handleSelect={this.handleSelect}
                        isRequest={!!requestId}
                        isPost={!!postUuid}
                        isEmail={isEmail}
                        isMass={isMass}
                        isFeedback={!!feedback}
                        permanentParams={templateParams}
                      />
                    </div>
                  )}

                  {hasVariables && this.state.tags.length > 0 && (
                    <div className={styles.variables}>
                      <span>{t('Common:TextTemplatesVariables')}</span>
                      <TemplateVariables
                        t={t}
                        ns='Templates'
                        api={
                          isToHided ? getSnippetVariables : getEmailVariables
                        }
                        tags={this.state.tags}
                        name_exclude={isConstructor ? 'recipient' : undefined}
                        handleSelect={this.handlePasteVariable}
                      />
                    </div>
                  )}

                  {hasVariables && !isAutomation && (
                    <div className={styles.variables}>
                      <NewVariableDropdown
                        handlePasteVariable={this.handlePasteVariable}
                      />
                    </div>
                  )}
                  <div style={{ position: 'relative' }}>
                    <WysiwygEditor
                      autoFocus={autoFocus}
                      t={t}
                      addLinks={!isChatroom}
                      uuid={this.props.uuid}
                      isOnDrag={this.state.onDrag}
                      disabled={editorDisabled}
                      value={quillProps.value}
                      setEditorRef={quillProps.setEditorRef}
                      account={account}
                      working={working}
                      setFocused={this.onFocusedEditor}
                      onDrop={this.finishFilesUpload}
                      onDragStart={onDragStart}
                      onInit={this.handleInit}
                      onChange={quillProps.onChange}
                      onFocus={quillProps.onFocus}
                      onBlur={this.props.onBlur}
                      onUpload={this.finishFilesUpload}
                    />
                    {this.props.renderFooter && this.props.renderFooter()}
                  </div>
                  {onShowExtraTools && (
                    <ExtraTools
                      isCreate={this.props.uuid === 'create'}
                      files={filesList}
                      proceedExt={this.props.proceedExt}
                      values={this.props.values}
                      isRequestThread={isRequestThread}
                      isMass={isMass}
                      onUpload={this.finishFilesUpload}
                      onShowUpload={this.showUploading}
                      onUpdateFiles={this.props.onUpdateFiles}
                      onRemoveDocs={onRemoveDocs}
                      onToggleMass={onToggleMass}
                      onAnalyzeDocs={onAnalyzeDocs}
                      onOpenFiles={this.handleOpenFiles}
                    />
                  )}
                  <RequestFileList
                    isEmailSendForm
                    preview
                    isRequestThread={isRequestThread}
                    isLimit={isLimit}
                    isBodyLimit={isLimit && isBodyLimit}
                    filesAsLinks={filesAsLinks}
                    headerStyle={{ marginTop: '16px' }}
                    showAddButton={showAddButton}
                    headerTitle={filesHeaderTitle}
                    files={files}
                    loading={filesUploading}
                    className={filesClass}
                    uploadingFiles={uploadingFiles}
                    outbound={outbound}
                    onShowExtraTools={onShowExtraTools}
                    onOpenFiles={this.handleOpenFiles}
                    onUpload={this.finishFilesUpload}
                    onStartUpload={this.startFilesUpload}
                    onRemove={this.handleRemove}
                    onChangeFilesAsLinks={this.handleFilesAsLinks}
                    onFilePreview={this.handleOpenPreview}
                  />
                </div>
              </NewFilesUpload>
            </div>
            <div className='messages__tools-wrap'>
              {!!this.props.onSubmit && (
                <div className='messages__tools-submit'>
                  <Button.Regular
                    className='messages__tools-send button--success'
                    disabled={disabledSubmit}
                    working={working}
                    onClick={this.submit}
                  >
                    {isMass || docTemplateId
                      ? `${this.props.t('SendMass', { count })}`
                      : this.props.t('Common:send')}
                  </Button.Regular>
                  <Button.Cancel working={working} onClick={this.cancel}>
                    {this.props.t('Common:Cancel')}
                  </Button.Cancel>
                  {(isMass || docTemplateId) && (
                    <div
                      style={{ position: 'absolute', top: '0', right: '10px' }}
                    >
                      <Button.Cancel
                        style={{ marginRight: '0.6rem' }}
                        disabled={
                          (!this.state.template?.id && !docTemplateId) ||
                          count < 1 ||
                          !bodySize
                        }
                        onClick={
                          isMass && this.state.template?.id && docTemplateId
                            ? this.confirmHandleAnalyze
                            : docTemplateId
                            ? this.analyzeDoc
                            : this.analyzeEmail
                        }
                      >
                        {this.props.t('Common:AnalyzeButton')}
                      </Button.Cancel>
                      <Button.Regular
                        disabled={count < 1}
                        onClick={this.handlePreview}
                      >
                        {this.props.t('Common:Preview')}
                      </Button.Regular>
                    </div>
                  )}
                </div>
              )}
              {this.renderAdditional()}
            </div>
          </div>
        </div>
        {this.state.selectTemplateModal && (
          <SelectTemplateModal
            isOpen={this.state.selectTemplateModal}
            onReplaceText={this.confirmReplaceText}
            onInsertText={this.confirmInsertText}
            onClose={this.hideSelectTemplateModal}
          />
        )}
        {this.state.selectAnalyzeTemplateModal && (
          <SelectAnalyzeTemplateModal
            isOpen={this.state.selectAnalyzeTemplateModal}
            onAnalyzeEmail={this.analyzeEmail}
            onAnalyzeDoc={this.analyzeDoc}
            onClose={this.hideSelectAnalyzeTemplateModal}
          />
        )}
        {this.state.isFilesModalOpen && (
          <RequestFilesModal
            isOpen={this.state.isFilesModalOpen}
            directoryId={directoryId}
            setSelectedFiles={this.props.setSelectedFiles}
            requestId={requestId}
            onClose={this.handleCloseModal}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
})

export default compose(
  withTranslation(['Mail', 'Templates']),
  connect(mapStateToProps)
)(SendMessageForm)
