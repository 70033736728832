// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import MailMessageShort from '../../../components/Mail/MailMessageShort'
import { getLanguageCode } from '../../../utils/commonSelectors'
import MailMessageFull from '../../../components/Mail/MailMessageFull'

type Props = {
  getForwardValues: (string, Object, Array<Object>, string, string) => Object,
  getReplyAllValues: (string, Object, Array<Object>, string, string) => Object,
  getReplyValues: (string, Object, Array<Object>, string, string) => Object,
  isMass?: boolean,
  isUpdating?: boolean,
  lastMessageUuid: string,
  message: Object,
  onAttach: (string, Array<Object>) => void,
  onClick: (?string) => void,
  onConvert: string => void,
  onDelete: string => void,
  onRestore: string => void,
  onToggleMass?: boolean => void,
  onUpdate: Object => void,
  pending?: boolean,
  selectedMessageUuid: ?string,
  shared: boolean,
  title: string,
}

const Message = (props: Props): Node => {
  const {
    selectedMessageUuid,
    lastMessageUuid,
    message,
    shared,
    title,
    isMass,
    isUpdating,
    onToggleMass,
  } = props

  const languageCode = useSelector(getLanguageCode)
  const { request } = useSelector(state => state.attachToRequestPopup)

  const isLastMessage = message.uuid === lastMessageUuid

  const handleCollapseMessage = () => props.onClick(null)

  if (message.uuid === selectedMessageUuid || isLastMessage) {
    const fullMessageProps = {
      ...message,
      pending: props.pending,
      key: message.uuid,
      onGetReplyValues: props.getReplyValues,
      onGetReplyAllValues: props.getReplyAllValues,
      onGetForwardValues: props.getForwardValues,
      onConverToRequest: props.onConvert,
      onAttachToRequest: props.onAttach,
      onDelete: props.onDelete,
      onRestore: props.onRestore,
      showReplyForm: isLastMessage,
      onCollapse: handleCollapseMessage,
      onResend: props.onResend,
    }

    if (isLastMessage) {
      fullMessageProps.isToHided = true
    }

    return (
      <MailMessageFull
        {...fullMessageProps}
        showAddButton
        createProps={{ ...fullMessageProps, files: [] }}
        languageCode={languageCode}
        softArchived={!!fullMessageProps.soft_archived}
        isLastMessage={isLastMessage}
        shared={shared}
        threadTitle={title}
        isMass={isMass}
        isUpdating={isUpdating}
        attached_request={request?.message_id === message.uuid ? request : null}
        scope='mail'
        onSave={props.onUpdate}
        onToggleMass={onToggleMass}
      />
    )
  }

  return (
    <MailMessageShort
      key={message.uuid}
      {...message}
      languageCode={languageCode}
      attached_request={request?.message_id === message.uuid ? request : null}
      onClickMessage={props.onClick}
    />
  )
}

export default Message
