// @flow

import React from 'react'
import type { Node } from 'react'
import { getMessageWithLinks } from './Message.utils'
import { linkify } from '../../../../utils/text'

type Props = { text: string }

const MessageText = (props: Props): Node => {
  const { text } = props

  return (
    <div
      className='ql-editor message-text'
      dangerouslySetInnerHTML={{
        __html: linkify(getMessageWithLinks(text)),
      }}
    />
  )
}

export default MessageText
