// @flow

import React, { useEffect } from 'react'
import type { Node } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import moment from 'moment'
import { get } from 'lodash-es'

import { isUserAuthorized, getUser } from '../utils/commonSelectors'
import { loadTranslations } from '../components/translations/translations.actions'
import { logout } from '../components/Auth/Login/Login.actions'
import type { User } from '../components/Auth/Login/Login.reducer'

const DEFAULT_ROUTE = '/'

type Props = {
  component: Node,
  isUserAuthorized: boolean,
  loadTranslations: () => { type: 'TRANSLATIONS_LOADING' },
  logout: (pathName?: string) => { pathName?: string, type: 'LOGOUT' },
  needLogout: boolean,
  translations: Object,
  user: User,
}

const PublicRoute = (props: Props) => {
  const {
    isUserAuthorized,
    location,
    needLogout,
    component,
    user,
    translations,
    ...rest
  } = props

  useEffect(() => {
    if (needLogout && isUserAuthorized) {
      const pathName = get(rest, ['computedMatch', 'url'])
      props.logout(pathName)
    }
  }, [])

  useEffect(() => {
    if (
      isUserAuthorized &&
      user.language_obj.code !== translations.translations.locale
    ) {
      props.loadTranslations()
      moment.locale(user.language_obj.code)
    }
  }, [isUserAuthorized])

  if (needLogout && isUserAuthorized) {
    return null
  }

  if (
    isUserAuthorized &&
    user.language_obj.code === translations.translations.locale
  ) {
    const redirect = location.params?.redirect || DEFAULT_ROUTE
    const currentHostname = window.location.hostname

    if (
      user.host &&
      currentHostname !== user.host &&
      currentHostname !== 'localhost'
    ) {
      window.location.replace(`https://${user.host}${redirect}`)

      return null
    }

    return <Redirect to={redirect} />
  }

  if (!isUserAuthorized) {
    return <Route component={component} {...rest} />
  }

  if (translations.loading) {
    return null // add normal loader
  }

  return null
}

PublicRoute.defaultProps = {
  needLogout: false,
}

const mapStateToProps = state => ({
  isUserAuthorized: isUserAuthorized(state),
  translations: state.translations,
  user: getUser(state),
})

const mapDispatchToProps = {
  loadTranslations,
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute)
