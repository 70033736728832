// @flow

import React from 'react'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash-es'
import type { Node } from 'react'

import { useOverflow, useSelected } from '../../../hooks'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalHead from '../../Modal/ModalHead'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import NewTable from '../../NewTable'
import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import { getList } from '../../../core/api/api.file'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import FilesIcon from '../../Files/FilesIcon'
import { getFormattedDate } from '../../../utils/utils'
import NewTableBody from '../../NewTable/NewTableBody'
import Loader from '../../Loader'
import Button from '../../Button'
import ModalButtons from '../../Modal/ModalButtons'
import { getRelatedPathList } from '../../Files/Files.utils'
import EmptyList from '../../EmptyList'
import Checkbox from '../../Checkbox'
import { FOLDER_TYPE } from '../../Files/Files.constants'

import styles from './RequestFilesModal.module.scss'
import styles2 from '../../Files/Files.module.scss'

type Props = { directoryId: number, isOpen: boolean, onClose: () => void }

const RequestFilesModal = (props: Props): Node => {
  const { isOpen, directoryId, requestId } = props

  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isInitiating, setInititating] = useState(false)
  const [files, setFiles] = useState(null)
  const [pageCount, setPageCount] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [folderId, setFolderId] = useState(directoryId)
  const [hasMore, setHasMore] = useState(false)

  const [selectedItems, changeSelected] = useSelected()

  const { t } = useTranslation('Request')

  useOverflow()

  const getCheckedFiles = objects =>
    Array.isArray(objects)
      ? objects.filter(f => f.type === FOLDER_TYPE || !!f.origin)
      : []

  useEffect(() => {
    if (!isLoading || !folderId) {
      return
    }

    getList({
      parent: folderId,
      page: currentPage,
      scope: 'request',
      object_id: requestId,
    })
      .then(data => {
        setFiles(files.concat(getCheckedFiles(data.objects)))

        setBreadcrumbs(getRelatedPathList(data.parent.path, directoryId))
        setPageCount(data.pages)
      })
      .finally(() => setLoading(false))
  }, [isLoading])

  useEffect(() => {
    if (!isInitiating || !folderId) {
      return
    }

    getList({
      parent: folderId,
      page: currentPage,
      scope: 'request',
      object_id: requestId,
    })
      .then(data => {
        setFiles(getCheckedFiles(data.objects))

        setBreadcrumbs(getRelatedPathList(data.parent.path, directoryId))
        setPageCount(data.pages)
      })
      .finally(() => setInititating(false))
  }, [isInitiating])

  useEffect(() => {
    if (!isInitiating) {
      setCurrentPage(1)
      setInititating(true)
    }
  }, [folderId])

  useEffect(() => {
    if (!isLoading && currentPage > 1) {
      setLoading(true)
    }
  }, [currentPage])

  useEffect(() => {
    setHasMore(
      !!(currentPage && !isLoading && pageCount && pageCount > currentPage)
    )
  }, [currentPage, isLoading, pageCount])

  if (!files) {
    return null
  }

  const loadMore = () => {
    if (isLoading || pageCount === currentPage) {
      return
    }

    setCurrentPage(currentPage + 1)
  }

  const loaderStyle = {
    margin: 0,
    padding: 0,
  }

  const imgStyle = {
    display: 'block',
    margin: '0 auto',
    width: '36px',
  }

  const handleClickBread = id => {
    setFolderId(id)
  }

  const handleAttach = () => {
    props.setSelectedFiles(selectedItems)
    props.onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={props.onClose}
    >
      <ModalHead
        showAlt
        title={t('RequestFilesTitle')}
        className={styles.modalHead}
      />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody className={styles.modalBody}>
        {breadcrumbs.length > 1 && (
          <div className={styles2.breadCrumbs}>
            {breadcrumbs.map((breadcrumb, index) => {
              const name = breadcrumb[2] ? t(breadcrumb[1]) : breadcrumb[1]

              if (breadcrumbs.length - 1 === index) {
                return <span key={breadcrumb[0]}>{name}</span>
              }

              return (
                <span
                  key={breadcrumb[0]}
                  onClick={() => handleClickBread(breadcrumb[0])}
                >
                  {name}
                </span>
              )
            })}
          </div>
        )}
        {files && files.length ? (
          <InfiniteScroll
            useWindow={false}
            loadMore={loadMore}
            hasMore={hasMore}
            threshold={100}
            initialLoad={false}
          >
            <NewTable>
              <NewTableHeader>
                <NewTableHeaderRow>
                  <NewTableHeaderCell
                    title={t('Files:FileNameSort')}
                    style={{ width: '82%' }}
                  />
                  <NewTableHeaderCell
                    title={t('Files:FileCreateSort')}
                    style={{ width: '18%' }}
                  />
                </NewTableHeaderRow>
              </NewTableHeader>
              <NewTableBody>
                {files.map(file => (
                  <NewTableBodyRow
                    key={file.id}
                    onClick={() => {
                      if (file.type !== FOLDER_TYPE) {
                        changeSelected(file)
                      } else {
                        setFolderId(file.id)
                      }
                    }}
                  >
                    <NewTableBodyCell
                      title={
                        file.is_persistent ? t(`Files:${file.name}`) : file.name
                      }
                      link={file.origin}
                    >
                      {file.type !== FOLDER_TYPE && (
                        <Checkbox
                          className={styles2.checkbox}
                          checked={map(selectedItems, 'id').includes(file.id)}
                          onChange={() => changeSelected(file)}
                        />
                      )}
                      <FilesIcon file={file} />
                    </NewTableBodyCell>
                    <NewTableBodyCell
                      title={
                        file.created ? getFormattedDate(file.created) : '-'
                      }
                    />
                  </NewTableBodyRow>
                ))}
              </NewTableBody>
            </NewTable>
          </InfiniteScroll>
        ) : (
          <EmptyList
            icon='folder'
            title={t('Files:FilesNotFound')}
            className={styles.emptyList}
          />
        )}
        {isLoading && (
          <Loader
            type='small'
            text={false}
            style={loaderStyle}
            imgStyle={imgStyle}
          />
        )}
      </ModalBody>
      <ModalButtons className={styles.modalButtons}>
        <Button.Save
          disabled={isLoading || !selectedItems.length}
          onClick={handleAttach}
        >
          {t('AttachFilesButton')}{' '}
          {!!selectedItems.length &&
            `(${selectedItems.length} ${t('FilesSelected')})`}
        </Button.Save>
        <Button.Cancel disabled={isLoading} onClick={props.onClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default RequestFilesModal
